<template>
  <div class="input-search">
    <div class="row">
      <input
        class="field"
        type="text"
        ref="search"
        :placeholder="placeholder"
        :value="value"
        :readonly="readonly"
        @input="onInput"
        @focus="emit('focus')"
        @blur="emit('blur')"
      />
      <atomic-icon id="search" class="search-icon" />
      <atomic-icon id="close" class="clear" v-if="hasClearIcon" @click="clearInput" />
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps({
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
    hasClearIcon: {
      type: Boolean,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
  });

  const search = ref(null);
  const focus = () => {
    search.value?.focus();
  };

  const blur = () => {
    search.value?.blur();
  };

  defineExpose({ focus, blur });

  const emit = defineEmits(['update:value', 'input', 'focus', 'blur']);
  const onInput = (e: Event): void => {
    const target = e.target as HTMLInputElement | null;
    if (target) {
      const inputValue = target.value;
      emit('update:value', inputValue);
      emit('input', inputValue);
    }
  };
  const clearInput = (): void => {
    emit('update:value', '');
    emit('input', '');
  };
</script>

<style src="~/assets/styles/components/form/input/search.scss" lang="scss" />
